import React from 'react'
import ReactDOM from 'react-dom'
import './scss/org-page-style.scss'
import { submitDeleteAvalonUser, submitOrgLessUserUpdate } from '../views/utils/api-utils'
import AlertDialogMessage from '../views/components/dialogs/user-dialogs/delete-user-alert'
import { withRouter } from 'react-router'
import axios from 'axios'
import MaterialTable from 'material-table'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import TablePagination from '@material-ui/core/TablePagination'
import EditTwoToneIcon from '@material-ui/icons/EditTwoTone'
import DeleteForeverTwoToneIcon from '@material-ui/icons/DeleteForeverTwoTone'
import SearchTwoToneIcon from '@material-ui/icons/SearchTwoTone'
import ClearIcon from '@material-ui/icons/Clear'
import LastPageTwoToneIcon from '@material-ui/icons/LastPageTwoTone'
import PageviewTwoToneIcon from '@material-ui/icons/PageviewTwoTone'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import FilterListIcon from '@material-ui/icons/FilterList'
import FirstPageIcon from '@material-ui/icons/FirstPage'
import EditOrgLessUsers from '../views/components/dialogs/org-less-users/edit-org-less-users'
import UnarchiveIcon from '@material-ui/icons/Unarchive'

const fontColor = '#A7A7BA'

const theme = createMuiTheme({
  typography: {
    caption: {
      color: fontColor,
      fontSize: 14
    },
    h6: {
      color: fontColor
    }
  }
})

const icons = {
  Search: React.forwardRef((props, ref) => <SearchTwoToneIcon ref={ref} />),
  NextPage: React.forwardRef((props, ref) => (
    <ArrowForwardIosIcon style={{ color: fontColor }} ref={ref} />
  )),
  LastPage: React.forwardRef((props, ref) => (
    <LastPageTwoToneIcon style={{ color: fontColor }} ref={ref} />
  )),
  SortArrow: React.forwardRef((props, ref) => <FilterListIcon ref={ref} />),
  PreviousPage: React.forwardRef((props, ref) => (
    <ArrowBackIosIcon style={{ color: fontColor }} ref={ref} />
  )),
  ViewColumn: React.forwardRef((props, ref) => (
    <PageviewTwoToneIcon style={{ color: fontColor }} ref={ref} />
  )),
  FirstPage: React.forwardRef((props, ref) => (
    <FirstPageIcon style={{ color: fontColor }} ref={ref} />
  )),
  ResetSearch: React.forwardRef((props, ref) => (
    <ClearIcon style={{ color: fontColor }} ref={ref} />
  )),
  Export: React.forwardRef((props, ref) => <UnarchiveIcon style={{ color: fontColor }} ref={ref} />)
}

const style = {
  position: 'absolute',
  fontFamily: 'verdana',
  fontSize: '14px',
  top: '120px',
  left: '250px',
  margin: '20px',
  backgroundColor: '#27293D',
  borderRadius: '0px',
  borderColor: '#353A53',
  borderStyle: 'solid',
  color: fontColor,
  width: 'calc(100vw - 300px)'
}

const columns = [
  { title: 'UserID', field: 'id', align: 'center', sorting: false },
  { title: 'First Name', field: 'firstName' },
  { title: 'Last Name', field: 'lastName' },
  { title: 'User Name', field: 'username' },
  { title: 'Email', field: 'email', sorting: false },
  { title: 'Email Validation', field: 'emailValid' },
  { title: 'Last Login', field: 'lastLogin' },
  { title: 'Invited By', field: 'invitedBy' },
  { title: 'Date Created', field: 'memberSince', type: 'date', sorting: false }
]

function OrgLessView(props) {
  const [loading, setIsLoading] = React.useState(true)
  const [rows, setRows] = React.useState([])

  React.useEffect(() => {
    getUsersFromOrg()
    // eslint-disable-next-line
  }, [])

  const getUsersFromOrg = () => {
    setIsLoading(true)
    axios
      .get(`/api/admin/organization-less/users/`)
      .then(response => {
        setRows(getRows(response.data))
        setIsLoading(false)
      })
      .catch(error => {
        return error
      })
  }

  const getRows = users => {
    const rows = users.map(user => ({
      id: user.id,
      firstName: user.First_name || user.firstName || user.first_name,
      lastName: user.Last_name || user.lastName || user.last_name,
      username: user.username || user.Username,
      email: user.email || user.Email,
      emailValid: user.emailValid || user.Email_Valid,
      lastLogin: user.LastLogin,
      invitedBy: user.InvitedBy === null ? 'N/A' : user.InvitedBy,
      memberSince: user.DateJoined
    }))
    return rows
  }

  const updateRow = newRow => {
    const newRows = rows.map(r => {
      if (r.tableData.id === newRow.tableData.id) {
        return newRow
      }
      return r
    })
    setRows(newRows)
  }

  return (
    <div>
      <ThemeProvider theme={theme}>
        <MaterialTable
          icons={icons}
          title={'Organization Less Users'}
          style={style}
          columns={columns}
          data={rows}
          isLoading={loading}
          options={{
            draggable: false,
            exportButton: true,
            exportAllData: true,
            pageSize: 10,
            headerStyle: {
              backgroundColor: '#ff3366',
              color: '#ffffff',
              borderBottomColor: '#000000'
            },
            searchFieldStyle: {
              color: fontColor
            },
            actionsCellStyle: {
              color: fontColor
            }
          }}
          components={{
            Pagination: props => <TablePagination {...props} className={'paginationOrgTable'} />
          }}
          actions={[
            {
              icon: () => <EditTwoToneIcon />,
              position: 'row',
              tooltip: 'Edit',
              onClick: (event, rowData) =>
                ReactDOM.render(
                  <EditOrgLessUsers
                    openDialog={true}
                    data={rowData}
                    userFirstName={rowData.firstName}
                    userLastName={rowData.lastName}
                    userUserName={rowData.username}
                    userEmail={rowData.email}
                    userID={rowData.id}
                    handleInput={updateRow}
                    inLineUpdate={(userID, dataResults) => {
                      submitOrgLessUserUpdate(userID, dataResults).then(() => getUsersFromOrg())
                    }}
                  />,
                  document.createElement('dialog-placeholder')
                )
            },
            {
              icon: () => <DeleteForeverTwoToneIcon />,
              position: 'row',
              tooltip: 'Delete From DB',
              onClick: (event, rowData) => {
                ReactDOM.render(
                  <AlertDialogMessage
                    openDialog={true}
                    userID={rowData.id}
                    userNameData={rowData.username}
                    handleDeleteObject={(orgId, userId) =>
                      submitDeleteAvalonUser(orgId, userId).then(() => getUsersFromOrg())
                    }
                  />,
                  document.createElement('dialog-placeholder')
                )
              }
            }
          ]}
        />
      </ThemeProvider>
    </div>
  )
}

export default withRouter(OrgLessView)
