import React from 'react'
import styles from './new-org-stepper-style.module.scss'
import NewOrgTextField from './new-org-textfield'
import RestrictionsPage from './restrictions-page'
import RestrictionCheckbox from './restrictions-checkbox-page'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import IconButton from '@material-ui/core/IconButton'
import DialogContent from '@material-ui/core/DialogContent'
import CloseIcon from '@material-ui/icons/Close'
import Typography from '@material-ui/core/Typography'
import Tooltip from '@material-ui/core/Tooltip'
import Paper from '@material-ui/core/Paper'

const steps = ['New Organization Name', 'Set Initial Values', 'Restrictions and Advanced Settings']

export default function StepperView({ openDialog, handleCreateNewOrg, data }) {
  const [open, setOpen] = React.useState(openDialog)
  const [activeStep, setActiveStep] = React.useState(0)
  const [orgName, setOrgName] = React.useState('')
  const [restrictions, setRestrictions] = React.useState({
    parentOrg: '',
    seats: -1,
    ip: '',
    date: '',
    reuseablePasswords: 4
  })
  const [stateCheckbox, setStateCheckbox] = React.useState({
    Enable2FA: false,
    restrictCustomerEnrichment: false,
    lockWorkSpaces: false,
    forceWorkSpaceShared: false,
    shareLockedGraphsChild: false,
    shareLockedGraphsParent: false,
    unlimitedSeats: true,
    unlimitedLicense: true
  })
  const refForm = React.useRef(null)

  const pageContent = [
    <NewOrgTextField onInput={event => setOrgName(event.target.value)} value={orgName} required />,
    <RestrictionsPage
      onInput={event => {
        setRestrictions({ ...restrictions, [event.target.name]: event.target.value })
      }}
      parentOrgValue={restrictions.parentOrg}
      maxSeatInputDisplay={restrictions.seats === -1 ? '\u221E' : restrictions.seats}
      ipRange={restrictions.ip}
      type={restrictions.seats === -1 ? 'text' : 'number'}
      data={data}
      disabled={restrictions.seats === -1}
      disabledDateField={stateCheckbox.unlimitedLicense}
      onChange={event => {
        setStateCheckbox({ ...stateCheckbox, [event.target.name]: event.target.checked })
        handleUnlimitedSeats(event)
        handleUnlimitedLicense(event)
      }}
      clear={() => setRestrictions({ ...restrictions, parentOrg: '' })}
      checkedSeats={stateCheckbox.unlimitedSeats}
      checkedLicense={stateCheckbox.unlimitedLicense}
      datePicked={date => handleDatePicked(date)}
      dateDisplay={restrictions.date ? restrictions.date : ''}
      reusablePasswordValue={restrictions.reuseablePasswords}
      required
    />,
    <RestrictionCheckbox
      parentOrgValue={restrictions.parentOrg}
      onChange={event =>
        setStateCheckbox({ ...stateCheckbox, [event.target.name]: event.target.checked })
      }
      values={stateCheckbox}
    />
  ]

  const handleNext = event => {
    if (!refForm.current.checkValidity()) {
      refForm.current.reportValidity()
      return
    }
    setActiveStep(prevActiveStep => prevActiveStep + 1)
    if (event.target.innerText === 'SUBMIT') {
      event.preventDefault()
      handleCreateNewOrg({
        Name: orgName,
        ParentOrg: restrictions.parentOrg ? restrictions.parentOrg : null,
        MaxSeatCounts: restrictions.seats,
        Enable2FA: stateCheckbox.Enable2FA,
        RestrictCustomerEnrichmentToLockedGraphs: stateCheckbox.restrictCustomerEnrichment,
        LockWorkspacesToOrgOnly: stateCheckbox.lockWorkSpaces,
        ForceWorkspaceSharedWithOrg: stateCheckbox.forceWorkSpaceShared,
        ShareLockedGraphsWithChildOrgs: stateCheckbox.shareLockedGraphsChild,
        ShareLockedGraphsWithParentOrg: stateCheckbox.shareLockedGraphsParent,
        EndDate: restrictions.date ? restrictions.date : null,
        ReusablePasswordCount: restrictions.reuseablePasswords ? restrictions.reuseablePasswords : 4
      })
    }
  }

  const handleBack = event => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }

  const handleClose = event => {
    setOpen(false)
  }

  const handleUnlimitedSeats = event => {
    if (event.target.name !== 'unlimitedSeats') {
      return
    }
    if (!stateCheckbox.unlimitedSeats && event.target.name === 'unlimitedSeats') {
      setRestrictions({ ...restrictions, seats: -1 })
    } else {
      setRestrictions({ ...restrictions, seats: 1 })
    }
  }

  const handleUnlimitedLicense = event => {
    if (event.target.name !== 'unlimitedLicense') {
      return
    }
    if (!stateCheckbox.unlimitedLicense && event.target.name === 'unlimitedLicense') {
      setRestrictions({ ...restrictions, date: null })
    }
    return stateCheckbox.unlimitedLicense
  }

  const handleDatePicked = event => {
    setRestrictions({ ...restrictions, date: event })
  }

  return (
    <div>
      <Dialog open={open}>
        <DialogContent classes={{ root: styles.rootContent }}>
          <Tooltip title="Close">
            <IconButton onClick={handleClose}>
              <CloseIcon style={{ color: styles.textColor }} />
            </IconButton>
          </Tooltip>
          <Stepper activeStep={activeStep} alternativeLabel classes={{ root: styles.stepCircle }}>
            {steps.map(label => (
              <Step key={label}>
                <StepLabel
                  classes={{
                    active: styles.activeLabel,
                    completed: styles.completedLabel
                  }}>
                  {label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>

          <Paper elevation={3} classes={{ root: styles.pageContentPaper }}>
            <form ref={refForm} onSubmit={e => e.preventDefault()}>
              {pageContent[activeStep]}
            </form>
          </Paper>
          <div className={styles.actionButtons}>
            {activeStep === steps.length ? (
              setOpen(false)
            ) : (
              <div>
                <Typography className={styles.instructions}>{steps[activeStep]}</Typography>
                <div>
                  <Button
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    className={activeStep === 0 ? styles.backButtonHidden : styles.backButton}
                    size="small"
                    color="primary">
                    Back
                  </Button>
                  <Button variant="contained" color="primary" onClick={handleNext} size="small">
                    {activeStep === steps.length - 1 ? 'Submit' : 'Next'}
                  </Button>
                </div>
              </div>
            )}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  )
}
