import React from 'react'
import styles from './new-org-textfield-style.module.scss'
import TextField from '@material-ui/core/TextField'

export default function NewOrgTextField({ value, onInput, error }) {
  return (
    <TextField
      autoFocus
      className={styles.textFieldStyle}
      label="Please enter a new organization name here."
      color="primary"
      type="text"
      fullWidth
      value={value}
      onInput={onInput}
      required
      variant="filled"
      helperText="This field is required. Skipping this step or leaving it blank is prohibited. Please choose carefully."
      InputProps={{
        classes: { root: styles.textFieldStyle }
      }}
      InputLabelProps={{
        style: { color: styles.textColor }
      }}
      FormHelperTextProps={{
        style: { color: styles.helperTextColor }
      }}
    />
  )
}
