import React from 'react'
import ReactDOM from 'react-dom'
import axios from 'axios'
import MessageSnackBar from '../../views/components/snackbars/message-snackbar'

export const fireUpSnackBarAlert = (operation, message) => {
  const body = document.getElementsByTagName('body')[0],
    newdiv = document.createElement('div') //create a div
  newdiv.id = 'global-messages' //add an id
  body.appendChild(newdiv) //append to the doc.body

  ReactDOM.render(
    <MessageSnackBar
      operations={operation}
      message={message}
      onExited={() => {
        const removeTag = document.getElementById('global-messages')
        removeTag.remove()
      }}
    />,
    document.getElementById('global-messages')
  )
}

export const createNewOrg = newOrg =>
  axios
    .post('/api/admin/organizations/', newOrg)
    .then(response =>
      fireUpSnackBarAlert('success', 'You have successfully created a new organization')
    )
    .catch(error =>
      fireUpSnackBarAlert(
        'error',
        `Create organization has failed, Reason: ${error.response.data[0].detail}.`
      )
    )

export const submitDeleteOrganization = id =>
  axios
    .delete(`/api/admin/organizations/${id}/delete`)
    .then(() => fireUpSnackBarAlert('success', 'You have successfully deleted the organization. '))
    .catch(error =>
      fireUpSnackBarAlert('error', 'Delete organization has failed, please try again. ')
    )

export const submitUpdatePatchOrganization = (id, toBeUpdated, states) =>
  axios
    .patch(`/api/admin/organizations/${id}`, toBeUpdated)
    .then(response =>
      fireUpSnackBarAlert('success', 'You have successfully updated an organization. ')
    )
    .catch(error =>
      fireUpSnackBarAlert(
        'error',
        `Update organization has failed, please try again. ${error.response.data[0].detail}`
      )
    )

export const submitNewAvalonUser = (id, listResult) =>
  axios
    .post(`/api/admin/organization/${id}/user/`, listResult)
    .then(() => fireUpSnackBarAlert('success', 'You have successfully created a new AvalonUser'))
    .catch(error => {
      fireUpSnackBarAlert(
        'error',
        `${error.response.data[0].username || error.response.data[0].email}: ${
          error.response.data[0].value
        }`
      )
      return error
    })

export const submitUpdateAvalonUser = (userId, orgId, data) =>
  axios
    .patch(`/api/admin/organization/${orgId}/user/${userId}`, data)
    .then(() => fireUpSnackBarAlert('success', 'You have successfully updated an AvalonUser'))
    .catch(error =>
      fireUpSnackBarAlert('error', 'Update AvalonUser has failed, please try again. ')
    )

export const submitDeleteAvalonUser = (userId, orgId) =>
  axios
    .delete(`/api/admin/organization/${orgId}/user/${userId}`)
    .then(response => fireUpSnackBarAlert('success', 'You have successfully deleted an AvalonUser'))
    .catch(error =>
      fireUpSnackBarAlert('error', 'Delete An AvalonUser has failed, please try again. ')
    )

export const submitAdminStatusUpdate = (userId, orgId, data) =>
  axios
    .patch(`/api/admin/organization/${orgId}/user/${userId}`, data)
    .then(response => {
      data.IsOrgAdmin
        ? fireUpSnackBarAlert(
            'success',
            `${response.data.username} has been promoted to an Organization Admin`
          )
        : fireUpSnackBarAlert(
            'success',
            `${response.data.username} is no longer an Organization Admin`
          )
    })
    .catch(error =>
      fireUpSnackBarAlert('error', 'Update AvalonUser has failed, please try again. ')
    )

export const submitOrgLessUserUpdate = (userId, data) =>
  axios
    .patch(`/api/admin/organization-less/users/${userId}`, data)
    .then(() => fireUpSnackBarAlert('success', 'You have successfully updated an AvalonUser'))
    .catch(error =>
      fireUpSnackBarAlert('error', 'Update AvalonUser has failed, please try again. ')
    )

export const getAllOrganizations = () =>
  axios
    .get('/api/admin/organizations/')
    .then(response => {
      return response.data
    })
    .catch(error => {
      return error.response.data
    })

export const resetPasswordForAvalonUser = (userId, data) => {
  axios
    .post(`/api/admin/user/${userId}/password-reset`, data)
    .then(response =>
      fireUpSnackBarAlert(
        'success',
        `The password reset request has been sent to ${response.data.username}`
      )
    )
    .catch(error =>
      fireUpSnackBarAlert('error', 'Password reset email has failed to send, please try again. ')
    )
}
