import React from 'react'
import styles from './scss/experimental-style.module.scss'

function Experimental(props) {
  return (
    <div className={styles.container}>
      <div>
        <a className={styles.text} href="/experimental/organizations">
          Organizations
        </a>
      </div>
      <div>
        <a className={styles.text} href="/experimental/users">
          Users
        </a>
      </div>
    </div>
  )
}

export default Experimental
