import React from 'react'
import styles from './breadcrumbs-style.module.scss'
import { Breadcrumbs, Link, Typography } from '@material-ui/core'
import { withRouter } from 'react-router-dom'

function UIBreadcrumbs({ history, location: { pathname }, location: { hash } }) {
  const pathNames = pathname.split('/').filter(x => x)
  return (
    <Breadcrumbs classes={{ root: styles.breadcrumbsStyle }}>
      {pathNames.map((name, index) => {
        const routeTo = `/${pathNames.slice(0, index + 1).join('/')}`
        const isLast = index === pathNames.length - 1
        return isLast ? (
          <Typography key={name}>{hash.slice(1) || name}</Typography>
        ) : (
          <Link
            classes={{ root: styles.breadcrumbsLinkText }}
            key={name}
            onClick={() => history.push(routeTo)}>
            {name}
          </Link>
        )
      })}
    </Breadcrumbs>
  )
}

export default withRouter(UIBreadcrumbs)
