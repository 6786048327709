export const processImportFile = file => {
  const inputFile = file
  const reader = new FileReader()

  return new Promise((resolve, reject) => {
    reader.onerror = () => {
      reader.abort()
      reject(new DOMException('Problem parsing input file.'))
    }

    reader.onload = event => {
      resolve(processInput(event.target.result))
    }
    reader.readAsText(inputFile)
  })
}

const processInput = t => {
  const result = []
  const fileContent = t.split('\n')
  let headers = fileContent[0].replaceAll(' ', '_').trim().split(',')

  for (var i = 1; i < fileContent.length; i++) {
    let obj = {}
    let currentLine = fileContent[i].split(',')

    for (var j = 0; j < headers.length; j++) {
      obj[headers[j]] = currentLine[j]
    }
    result.push(obj)
  }

  return result
}
