import React from 'react'
import styles from './delete-user-alert-style.module.scss'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DeleteIcon from '@material-ui/icons/Delete'
import Typography from '@material-ui/core/Typography'

export default function AlertDialogMessage(props) {
  const [open, setOpen] = React.useState(props.openDialog)
  var userID = props.userID
  var orgID = props.orgID
  var username = props.userNameData
  const handleClose = () => {
    setOpen(false)
  }

  const handleSubmit = event => {
    event.preventDefault()
    props.handleDeleteObject(userID, orgID)
    setOpen(false)
  }

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogContent classes={{ root: styles.dialogBox }}>
          <Typography variant="h5" align="left" className={styles.dialogTitle}>
            Delete AvalonUser
          </Typography>
          <DialogContentText style={{ color: styles.textColor }}>
            You are about to delete the following AvalonUser:{' '}
            {<ins style={{ color: styles.helperTextColor, fontWeight: 'bold' }}>{username}</ins>},
            are you sure about it?
          </DialogContentText>
          <form onSubmit={handleSubmit}>
            <div className={styles.actionButtons}>
              <Button onClick={handleClose} color="secondary" size="small">
                Dismiss
              </Button>
              <Button
                autoFocus
                variant="contained"
                color="secondary"
                startIcon={<DeleteIcon />}
                type="submit"
                size="small">
                Delete
              </Button>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  )
}
