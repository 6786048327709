import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import MailOutlineIcon from '@material-ui/icons/MailOutline'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'

const ResetPasswordForAvalonUser = (props) => {
  const [open, setOpen] = React.useState(props.openDialog)
  const {userID, userNameData: username} = props
  const handleClose = () => {
    setOpen(false)
  }

  const handleSubmit = event => {
    event.preventDefault()
    props.resetUserPassword(userID, { 'ResetPassword': true })
    setOpen(false)
  }

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle id="alert-dialog-title">{'Reset Password for Avalon User: '}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to send a password reset email to the following Avalon User:{' '}
            {<ins style={{ color: 'blue', fontWeight: 'bold' }}>{username}</ins>}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <form onSubmit={handleSubmit}>
            <Button onClick={handleClose} color="secondary" size="small">
              Dismiss
            </Button>
            <Button
              autoFocus
              variant="contained"
              color="secondary"
              startIcon={<MailOutlineIcon />}
              type="submit"
              size="small">
              Submit
            </Button>
          </form>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default ResetPasswordForAvalonUser
