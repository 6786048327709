import cookies from 'js-cookie'

export var setUserCookie = value => {
  cookies.set('authToken', value)
}

export var getUserCookie = name => {
  return cookies.get(name)
}

export var getAllUserCookie = () => {
  return cookies.get()
}

export var removeUserCookie = name => {
  cookies.remove(name)
}
