import React, { useState } from 'react'
import styles from './scss/login-page-style.module.scss'
import Button from '@material-ui/core/Button'
import { useHistory } from 'react-router-dom'
import TextField from '@material-ui/core/TextField'
import axios from 'axios'
import Alert from '@material-ui/lab/Alert'
import logo from '../assets/admin-logo.svg'
import Snackbar from '@material-ui/core/Snackbar'
import { setUserCookie } from '../views/utils/auth-service-cookie.js'

function Login() {
  const [state, setState] = useState({ username: '', password: '' })
  const [validUsername, setValidUsername] = useState(false)
  const [validPassword, setValidPassword] = useState(false)
  const [open, setOpen] = useState(false)
  const [errorResponse, setErrorResponse] = useState('')

  const history = useHistory()

  const handleLogin = event => {
    event.preventDefault()
    axios
      .post('/api/token-auth', {
        username: state.username,
        password: state.password
      })
      .then(response => {
        if (response.status === 200) {
          setUserCookie(response.data.token)
          localStorage.setItem('username', state.username)
          axios.defaults.headers.common['Authorization'] = `Token ${response.data.token}`
          history.push('/dashboard')
        }
      })
      .catch(error => {
        setOpen(true)
        setValidUsername(true)
        setValidPassword(true)
        setErrorResponse(error.response.data.non_field_errors)
      })
  }

  const handleChange = event => {
    event.preventDefault()
    setState({ ...state, [event.target.name]: event.target.value })
  }

  return (
    <div className={styles.contentContainer}>
      <img className={styles.logo} alt="Cyberscape Logo" src={logo} />
      <form onSubmit={handleLogin}>
        <div className={styles.formContainer}>
          <div>
            <TextField
              classes={{
                root: styles.textFieldUsername
              }}
              autoComplete="username"
              autoFocus
              required
              label="Username"
              variant="outlined"
              name="username"
              onInput={handleChange}
              error={validUsername}
              InputProps={{
                classes: { input: styles.textFieldUsernameInput }
              }}
              InputLabelProps={{ classes: { root: styles.textFieldLabel } }}
            />
          </div>
          <br />
          <div>
            <TextField
              classes={{
                root: styles.textFieldPassword
              }}
              autoComplete="current-password"
              required
              label="Password"
              variant="outlined"
              name="password"
              type="password"
              onInput={handleChange}
              error={validPassword}
              InputProps={{
                classes: { input: styles.textFieldPasswordInput }
              }}
              InputLabelProps={{ classes: { root: styles.textFieldLabel } }}
            />
          </div>
          <br />
          <Snackbar open={open} autoHideDuration={6000} onClose={() => setOpen(false)}>
            <Alert variant="filled" severity="error">
              {errorResponse}
            </Alert>
          </Snackbar>
          <br />
          <div className={styles.LoginButton}>
            <Button color="primary" type="submit" variant="contained">
              Login
            </Button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default Login
