import React from 'react'
import styles from './restrictions-page-style.module.scss'
import ClearIcon from '@material-ui/icons/Clear'
import TextField from '@material-ui/core/TextField'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import MenuItem from '@material-ui/core/MenuItem'
import IconButton from '@material-ui/core/IconButton'
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'

export default function RestrictionsPage(props) {
  const handleAdornmentView = () => {
    if (props.parentOrgValue === '') {
      return null
    }
    return (
      <IconButton style={{ marginRight: 25, color: styles.textColor }} onClick={props.clear}>
        <ClearIcon />
      </IconButton>
    )
  }

  return (
    <div>
      <TextField
        classes={{ root: styles.root }}
        name="parentOrg"
        select
        variant="outlined"
        fullWidth
        label="Parent Organization"
        value={props.parentOrgValue}
        onChange={props.onInput}
        margin="dense"
        SelectProps={{
          MenuProps: {
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left'
            },
            getContentAnchorEl: null
          }
        }}
        InputProps={{
          endAdornment: handleAdornmentView(),
          classes: { root: styles.textFieldStyle }
        }}
        InputLabelProps={{
          style: { color: styles.textColor }
        }}
        helperText="Please select a parent Organization if eligible."
        FormHelperTextProps={{
          style: { color: styles.helperTextColor }
        }}>
        {props.data.map(option => (
          <MenuItem
            key={option.name}
            value={option.id}
            classes={{ root: styles.menuItem, selected: styles.selectedItem }}>
            {option.name}
          </MenuItem>
        ))}
      </TextField>
      <FormControlLabel
        control={
          <Checkbox
            style={{ marginLeft: '15px' }}
            checked={props.checkedSeats}
            onChange={props.onChange}
            name="unlimitedSeats"
            color="primary"
          />
        }
        label="Unlimited Seats"
        style={{ color: styles.textColor }}
      />
      <div className={styles.datePicker}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDateTimePicker
            autoComplete="off"
            name="date"
            inputVariant="outlined"
            disablePast
            format="MM/dd/yyyy :- hh:mm a"
            value={props.dateDisplay}
            onChange={event => props.datePicked(event)}
            disabled={props.disabledDateField}
            margin="dense"
            animateYearScrolling={true}
            helperText="Pick a date and time for license expiration."
            error={false}
            autoOk={true}
            clearable
            InputProps={{
              classes: { root: styles.textFieldStyle }
            }}
            InputLabelProps={{
              style: { color: styles.textColor }
            }}
            FormHelperTextProps={{
              style: { color: styles.helperTextColor }
            }}
            KeyboardButtonProps={{
              style: { color: styles.textColor }
            }}
          />
        </MuiPickersUtilsProvider>
        <FormControlLabel
          control={
            <Checkbox
              style={{ marginLeft: '15px' }}
              checked={props.checkedLicense}
              onChange={props.onChange}
              name="unlimitedLicense"
              color="primary"
            />
          }
          label="Unlimited License"
          style={{ color: styles.textColor }}
        />
        <TextField
          margin="dense"
          name="reuseablePasswords"
          label="Reusable Password Count"
          value={props.reusablePasswordValue}
          type="number"
          // fullWidth
          onChange={props.onInput}
          variant="outlined"
          InputProps={{
            inputProps: {
              min: 1
            },
            classes: { root: styles.textFieldStyle }
          }}
          FormHelperTextProps={{
            style: { color: styles.helperTextColor }
          }}
          InputLabelProps={{
            style: { color: styles.textColor }
          }}
          helperText="The default value for this field is 4."
        />
      </div>
    </div>
  )
}
