import React from 'react'
import styles from './app-bar-top-style.module.scss'
import { withRouter } from 'react-router-dom'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import logo from '../../../../assets/admin-logo.svg'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import { removeUserCookie } from '../../../utils/auth-service-cookie'
import Popover from '@material-ui/core/Popover'

function AppBarTop(props) {
  const { history } = props
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = event => {
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleLogOut = () => {
    removeUserCookie('authToken')
    history.push('/')
    delete localStorage.username
  }

  return (
    <div>
      <AppBar classes={{ root: styles.appBar }} style={{ backgroundColor: '#27293D' }}>
        <Toolbar>
          <Typography variant="h5" gutterBottom>
            Welcome {localStorage.username}
          </Typography>
          <img className={styles['imageStyle']} alt="avalon_icon" src={logo} />
          <IconButton
            aria-controls="actionsMenu"
            aria-haspopup="true"
            edge="start"
            color="inherit"
            style={{ marginLeft: '30px' }}
            onClick={handleClick}>
            <MenuIcon />

            <Popover
              classes={{ paper: styles.menuWindow }}
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              transformOrigin={{ vertical: 'top', horizontal: 'center' }}
              getContentAnchorEl={null}
              onClose={handleClose}>
              <MenuItem onClick={handleLogOut}>
                Logout
                <ExitToAppIcon className={styles['interiorIconList']} />
              </MenuItem>
            </Popover>
          </IconButton>
        </Toolbar>
      </AppBar>
      ;
    </div>
  )
}

export default withRouter(AppBarTop)
