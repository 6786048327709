import React from 'react'
import MaterialTable from 'material-table'
import { withRouter } from 'react-router-dom'
import styles from './organizations-main-table-style.module.scss'
import { Link } from 'react-router-dom'
import axios from 'axios'
import EditTwoToneIcon from '@material-ui/icons/EditTwoTone'
import AddBoxTwoToneIcon from '@material-ui/icons/AddBoxTwoTone'
import DeleteForeverTwoToneIcon from '@material-ui/icons/DeleteForeverTwoTone'
import ClearIcon from '@material-ui/icons/Clear'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import SearchTwoToneIcon from '@material-ui/icons/SearchTwoTone'
import LastPageTwoToneIcon from '@material-ui/icons/LastPageTwoTone'
import PageviewTwoToneIcon from '@material-ui/icons/PageviewTwoTone'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import FilterListIcon from '@material-ui/icons/FilterList'
import FirstPageIcon from '@material-ui/icons/FirstPage'
import EditSectionDialog from '../edit-org-dialog/edit-organization-main-dialog'
import StepperView from '../org-creation-dialog/new-org-stepper'
import PublishIcon from '@material-ui/icons/Publish'
import AlertDialog from './dialogs/org-dialogs/delete-org-alert-dialog'
import TablePagination from '@material-ui/core/TablePagination'
import ReactDOM from 'react-dom'
import {
  submitUpdatePatchOrganization,
  submitDeleteOrganization,
  createNewOrg
} from '../utils/api-utils'

const fontColor = '#A7A7BA'

const theme = createMuiTheme({
  typography: {
    caption: {
      color: fontColor,
      fontSize: 14
    }
  }
})

const icons = {
  Search: React.forwardRef((props, ref) => <SearchTwoToneIcon ref={ref} />),
  NextPage: React.forwardRef((props, ref) => (
    <ArrowForwardIosIcon style={{ color: fontColor }} ref={ref} />
  )),
  LastPage: React.forwardRef((props, ref) => (
    <LastPageTwoToneIcon style={{ color: fontColor }} ref={ref} />
  )),
  SortArrow: React.forwardRef((props, ref) => <FilterListIcon ref={ref} />),
  PreviousPage: React.forwardRef((props, ref) => (
    <ArrowBackIosIcon style={{ color: fontColor }} ref={ref} />
  )),
  ViewColumn: React.forwardRef((props, ref) => (
    <PageviewTwoToneIcon style={{ color: fontColor }} ref={ref} />
  )),
  FirstPage: React.forwardRef((props, ref) => (
    <FirstPageIcon style={{ color: fontColor }} ref={ref} />
  )),
  ResetSearch: React.forwardRef((props, ref) => (
    <ClearIcon style={{ color: fontColor }} ref={ref} />
  )),
  Export: React.forwardRef((props, ref) => <PublishIcon style={{ color: fontColor }} ref={ref} />)
}

const style = {
  position: 'absolute',
  fontFamily: 'verdana',
  fontSize: '14px',
  top: '120px',
  left: '250px',
  margin: '20px',
  backgroundColor: '#27293D',
  borderRadius: '0px',
  borderColor: '#353A53',
  borderStyle: 'solid',
  color: fontColor,
  width: 'calc(100vw - 300px)'
}

const columns = [
  {
    title: 'OrgID',
    field: 'companyKey',
    sorting: false,
    width: 175
  },
  {
    render: rowData => {
      return (
        <div>
          <Link
            to={{
              pathname: `/organizations/${rowData.companyKey}`,
              hash: rowData.companyName,
              state: rowData.maxSeats
            }}
            style={{ color: fontColor }}>
            {rowData.companyName}
          </Link>
        </div>
      )
    },
    title: 'Company Name',
    field: 'companyName'
  },
  {
    title: 'Subsidiary Company',
    field: 'subsidiary',
    align: 'center'
  },
  {
    title: 'Max Seats',
    field: 'maxSeats',
    align: 'center'
  },
  {
    title: 'End Date',
    field: 'endDate',
    align: 'center',
    type: 'date'
  },
  {
    title: 'Number of Users',
    field: 'numberOfUsers',
    type: 'numeric',
    align: 'center'
  }
]

function OrganizationsMainTable() {
  const [rows, setRows] = React.useState([])
  const [rawRows, setRawRows] = React.useState([])
  const [loading, setIsLoading] = React.useState(true)
  const orgIdPlusName = rawRows.map(companyName => {
    return { name: companyName.Name, id: companyName.id }
  })

  React.useEffect(() => {
    getOrgData()
    // eslint-disable-next-line
  }, [])

  const getOrgData = () => {
    setIsLoading(true)
    axios
      .get('/api/admin/organizations/')
      .then(response => {
        getRows(response.data)
        setRawRows(response.data)
        setIsLoading(false)
      })
      .catch(error => {
        return error
      })
  }

  const getRows = results => {
    const rows = results.map(name => ({
      companyKey: name.id,
      companyName: name.Name,
      subsidiary: name.ChildOrganizations.map(element => {
        return ` \u2022 ${element.Name}`
      }),
      maxSeats: name.MaxSeatCounts < 0 ? 'Unlimited' : name.MaxSeatCounts,
      endDate: name.EndDate,
      numberOfUsers: name.Members.length
    }))
    setRows(rows)
  }

  const getOrgObject = id => {
    return rawRows.filter(key => key.id === id)
  }

  return (
    <div>
      <ThemeProvider theme={theme}>
        <MaterialTable
          icons={icons}
          style={style}
          title="Organizations List"
          columns={columns}
          isLoading={loading}
          data={rows}
          options={{
            draggable: false,
            exportButton: true,
            exportAllData: true,
            pageSize: 10,
            search: true,
            headerStyle: {
              backgroundColor: '#3366ff',
              color: '#ffffff',
              borderBottomColor: '#000000'
            },
            rowStyle: {
              color: fontColor
            },
            searchFieldStyle: {
              color: fontColor,
              width: '100%'
            },
            actionsCellStyle: {
              color: fontColor
            }
          }}
          components={{
            Pagination: props => (
              <TablePagination {...props} className={styles.paginationOrgMainTable} />
            )
          }}
          actions={[
            {
              icon: () => <EditTwoToneIcon />,
              tooltip: 'Edit',
              onClick: (event, rowData) =>
                ReactDOM.render(
                  <EditSectionDialog
                    orgNameAttribute={rowData.companyName}
                    dataFromAPI={rowData.companyKey}
                    openDialog={true}
                    data={getOrgObject(rowData.companyKey)}
                    allOrgs={orgIdPlusName.filter(r => r.id !== rowData.companyKey)}
                    handleUpdateObject={(apiData, restrictionsAttr) =>
                      submitUpdatePatchOrganization(apiData, restrictionsAttr).then(() =>
                        getOrgData()
                      )
                    }
                  />,
                  document.createElement('dialog-placeholder')
                )
            },
            {
              icon: () => <DeleteForeverTwoToneIcon />,
              tooltip: 'Delete',
              onClick: (event, rowData) => {
                ReactDOM.render(
                  <AlertDialog
                    openDialog={true}
                    orgNameAttribute={rowData.companyName}
                    dataFromAPI={rowData.companyKey}
                    handleDeleteObject={orgId =>
                      submitDeleteOrganization(orgId).then(() => getOrgData())
                    }
                  />,
                  document.createElement('dialog-placeholder')
                )
              }
            },
            {
              icon: () => <AddBoxTwoToneIcon style={{ color: fontColor }} />,
              tooltip: 'Add',
              position: 'toolbar',
              onClick: () =>
                ReactDOM.render(
                  <StepperView
                    data={orgIdPlusName}
                    handleCreateNewOrg={newOrgName =>
                      createNewOrg(newOrgName).then(() => getOrgData())
                    }
                    openDialog={true}
                  />,
                  document.createElement('dialog-placeholder')
                )
            }
          ]}
        />
      </ThemeProvider>
    </div>
  )
}

export default withRouter(OrganizationsMainTable)
