import React from 'react'
import styles from './scss/dashboard-style.module.scss'
import ChartTotalUsersPerYear from '../views/dashboard-data-visualization/chart-users-per-current-year'
import TopFiveHighUsers from '../views/dashboard-data-visualization/pie-total-users-per-org'
import Grid from '@material-ui/core/Grid'

export default function Dashboard(props) {
  return (
    <div>
      <Grid container className={styles.container} spacing={2} style={{ top: '120px' }}>
        <Grid item xs={12} lg={8}>
          <ChartTotalUsersPerYear />
        </Grid>
        <Grid item xs={12} lg={4}>
          <TopFiveHighUsers />
        </Grid>
        <Grid item xs={12}></Grid>
      </Grid>
    </div>
  )
}
