import React from 'react'
import axios from 'axios'
import MaterialTable from 'material-table'
import { fireUpSnackBarAlert } from '../views/utils/api-utils'
// Import Icons
import SearchIcon from '@material-ui/icons/Search'
import GetAppIcon from '@material-ui/icons/GetApp'
import ClearIcon from '@material-ui/icons/Clear'
import FirstPageIcon from '@material-ui/icons/FirstPage'
import LastPageIcon from '@material-ui/icons/LastPage'
import NextPageIcon from '@material-ui/icons/NavigateNext'
import PreviousPageIcon from '@material-ui/icons/NavigateBefore'
import SortIcon from '@material-ui/icons/Sort'

const fontColor = '#A7A7BA'

const style = {
  position: 'absolute',
  fontFamily: 'verdana',
  fontSize: '14px',
  top: '120px',
  left: '250px',
  margin: '20px',
  backgroundColor: '#27293D',
  borderRadius: '0px',
  borderColor: '#353A53',
  borderStyle: 'solid',
  color: fontColor,
  width: 'calc(100vw - 300px)'
}

const tableIcons = {
  Search: React.forwardRef((props, ref) => <SearchIcon style={{ color: fontColor }} ref={ref} />),
  Export: React.forwardRef((props, ref) => <GetAppIcon style={{ color: fontColor }} ref={ref} />),
  ResetSearch: React.forwardRef((props, ref) => (
    <ClearIcon style={{ color: fontColor }} ref={ref} />
  )),
  FirstPage: React.forwardRef((props, ref) => (
    <FirstPageIcon style={{ color: fontColor }} ref={ref} />
  )),
  LastPage: React.forwardRef((props, ref) => (
    <LastPageIcon style={{ color: fontColor }} ref={ref} />
  )),
  NextPage: React.forwardRef((props, ref) => (
    <NextPageIcon style={{ color: fontColor }} ref={ref} />
  )),
  PreviousPage: React.forwardRef((props, ref) => (
    <PreviousPageIcon style={{ color: fontColor }} ref={ref} />
  )),
  SortArrow: React.forwardRef((props, ref) => <SortIcon style={{ color: 'white' }} ref={ref} />)
}

// Needed to create the table
// Material table documentation: https://material-table.com/#/docs/get-started
const columns = [
  { title: 'Organization', field: 'organization', align: 'center' },
  { title: 'First Name', field: 'firstName', align: 'center' },
  { title: 'Last Name', field: 'lastName', align: 'center' },
  { title: 'Username', field: 'username', align: 'center' },
  { title: 'Email', field: 'email', align: 'center' },
  {
    title: 'Last Login',
    field: 'lastLogin',
    type: 'datetime',
    dateSetting: { locale: 'en-US' },
    align: 'center'
  }
]

function Users(props) {
  const [rows, setRows] = React.useState([])

  React.useEffect(() => {
    const getUsers = () => {
      axios
        .get('/api/admin/users/')
        .then(response => {
          setRows(getRows(response.data))
        })
        .catch(error => {
          fireUpSnackBarAlert('error', 'Unable to load users, please try again.')
          return error
        })
    }

    getUsers()
  }, []) // Empty array here to only run on mount and unmount, stopping infinite loops



  const getRows = users => {
    const rows = users?.map(user => ({
      id: user.id,
      organization: user.Organization_name,
      firstName: user.FirstName,
      lastName: user.LastName,
      username: user.username,
      email: user.email,
      lastLogin: user.LastLogin
    }))
    return rows
  }

  return (
    <div>
      <MaterialTable
        title={<div>All Cyberscape Users</div>}
        columns={columns}
        data={rows}
        options={{
          pageSize: 50,
          exportButton: true,
          headerStyle: {
            backgroundColor: '#3366ff',
            color: 'white',
            borderBottomColor: 'black'
          }
        }}
        style={style}
        icons={tableIcons}
      />
    </div>
  )
}

Users.propTypes = {}

export default Users
