import React from 'react'
import { withRouter } from 'react-router-dom'
import styles from './sidebar-style.module.scss'
import { Drawer, ListItem, List, ListItemIcon, ListItemText, Tooltip } from '@material-ui/core/'
import AppBarTop from '../sidebar/app-bar/app-bar-top'
import UIBreadcrumbs from '../breadcrumbs/breadcrumbs-ui'
import Collapse from '@material-ui/core/Collapse'
import PersonOutlineIcon from '@material-ui/icons/PersonOutline'
import AccountTreeIcon from '@material-ui/icons/AccountTree'
import DraftsIcon from '@material-ui/icons/Drafts'
import HomeIcon from '@material-ui/icons/Home'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import DashboardIcon from '@material-ui/icons/Dashboard'

function Sidebar(props) {
  const { history } = props
  const [open, setOpen] = React.useState(false)

  const toggleOrgSubMenu = event => {
    event.stopPropagation()
    setOpen(!open)
  }

  return (
    <div>
      <UIBreadcrumbs />
      <AppBarTop />
      <Drawer variant="permanent" classes={{ paper: styles.drawerPaper }}>
        <List component="nav" aria-labelledby="nested-list-subheader">
          <ListItem
            button
            onClick={event => history.push('/dashboard')}
            classes={{ button: styles.drawerAttributer }}>
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItem>
          <ListItem
            button
            onClick={event => history.push('/organizations')}
            classes={open ? { button: styles.extendedTab } : { button: styles.drawerAttributer }}>
            <ListItemIcon>
              <AccountTreeIcon />
            </ListItemIcon>
            <ListItemText primary="Organizations" />
            <div>
              {open ? (
                <ExpandLess onClick={toggleOrgSubMenu} classes={{ root: styles.arrowButton }} />
              ) : (
                <Tooltip title="expand">
                  <ExpandMore onClick={toggleOrgSubMenu} classes={{ root: styles.arrowButton }} />
                </Tooltip>
              )}
            </div>
          </ListItem>

          <Collapse
            in={open}
            timeout="auto"
            unmountOnExit
            classes={{ container: styles.collapseArea }}>
            <List component="div">
              <ListItem
                button
                className={styles['nested']}
                onClick={event => history.push('/organization-less')}>
                <ListItemIcon>
                  <PersonOutlineIcon />
                </ListItemIcon>
                <ListItemText primary="Org-Less Users" />
              </ListItem>
            </List>
          </Collapse>
          <ListItem button classes={{ button: styles.drawerAttributer }}>
            <ListItemIcon>
              <DraftsIcon />
            </ListItemIcon>
            <ListItemText primary="On-Board" />
          </ListItem>
          <ListItem 
            button
            onClick={event => history.push('/experimental')}
            classes={{ button: styles.drawerAttributer }}>
            <ListItemIcon>
              <HomeIcon/>
            </ListItemIcon>
            <ListItemText primary="Experimental" />
          </ListItem>
        </List>
      </Drawer>
    </div>
  )
}

export default withRouter(Sidebar)
