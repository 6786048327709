import React from 'react'
import axios from 'axios'
import style from './dashboard-charts-graph-style.module.scss'
import { Pie } from 'react-chartjs-2'
import 'chartjs-plugin-datalabels'
import Paper from '@material-ui/core/Paper'

export default function TopFiveHighUsers(props) {
  const [allOrgsAndUsersSize, setAllOrgsAndUsersSize] = React.useState([])

  React.useEffect(() => {
    getAllAvalonUsers()
    // eslint-disable-next-line
  }, [])

  const getAllAvalonUsers = () => {
    axios
      .get('/api/admin/organizations/')
      .then(response => {
        setAllOrgsAndUsersSize(groupAllUsersByOrg(response.data))
      })
      .catch(error => {
        return error
      })
  }

  const groupAllUsersByOrg = data => {
    const groupedByOrgs = data
      .map(org => {
        return { Name: org.Name, usersCount: org.Members.length }
      })
      .sort((a, b) => {
        return b.usersCount - a.usersCount
      })
      .slice(0, 5)

    return groupedByOrgs
  }

  const dataSet = {
    labels: allOrgsAndUsersSize.filter(users => users.usersCount !== 0).map(org => org.Name),
    datasets: [
      {
        label: 'New Avalon Users',
        fill: true,
        backgroundColor: [
          style.pieBackGroundColor1,
          style.pieBackGroundColor2,
          style.pieBackGroundColor3,
          style.pieBackGroundColor4,
          style.pieBackGroundColor5
        ],
        borderColor: [
          style.pieBorderColor1,
          style.pieBorderColor2,
          style.pieBorderColor3,
          style.pieBorderColor4,
          style.pieBorderColor5
        ],
        borderWidth: 1,
        data: allOrgsAndUsersSize.map(data => data.usersCount),
        type: 'pie',
        title: 'Hi'
      }
    ]
  }

  return (
    <div>
      <Paper
        className={style.paperGraph}
        elevation={3}
        style={{ backgroundColor: style.primaryPaperBackgroundColor }}>
        <Pie
          data={dataSet}
          options={{
            title: { display: true, text: 'Top 5 Largest Orgs' },
            maintainAspectRatio: false,
            plugins: {
              datalabels: {
                display: val => val.dataset.data[val.dataIndex] !== 0,
                color: 'white',
                anchor: 'center'
              }
            }
          }}></Pie>
      </Paper>
    </div>
  )
}
