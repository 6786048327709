import React from 'react'
import style from './dashboard-charts-graph-style.module.scss'
import axios from 'axios'
import { Bar } from 'react-chartjs-2'
import 'chartjs-plugin-datalabels'
import Paper from '@material-ui/core/Paper'

export default function ChartTotalUsersPerYear(props) {
  const [allUsers, setAllUsers] = React.useState([])
  const [pastYearUsers, setPastYearUsers] = React.useState([])
  const currentYear = new Date().getFullYear()
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ]

  React.useEffect(() => {
    getAllAvalonUsers()
    // eslint-disable-next-line
  }, [])

  const getAllAvalonUsers = () => {
    axios
      .get(`api/admin/data-visualization`)
      .then(response => {
        setAllUsers(getUsersByMonth(response.data))
      })
      .catch(error => {
        return error
      })
  }

  const getUsersByMonth = data => {
    const currentYear = new Date().getFullYear()
    const groupedUsersPerDate = data
      .filter(user => new Date(user.DateJoined).getFullYear() === currentYear)
      .map(user => user.DateJoined)
      .reduce((month, date) => {
        var monthCount = new Date(date).toString().slice(4, 7)
        if (!month.hasOwnProperty(monthCount)) {
          month[monthCount] = 0
        }
        month[monthCount]++
        return month
      }, {})
    const checkCountValue = months.map((x, i) => {
      return Object.keys(groupedUsersPerDate).includes(x)
        ? { [x]: groupedUsersPerDate[x] }
        : { [x]: 0 }
    })
    getUsersFromPreviousYear(data)
    return checkCountValue
  }

  const getUsersFromPreviousYear = data => {
    const pastYear = new Date().getFullYear() - 1
    const groupedUsersPerDate = data
      .filter(user => new Date(user.DateJoined).getFullYear() === pastYear)
      .map(user => user.DateJoined)
      .reduce((month, date) => {
        var monthCount = new Date(date).toString().slice(4, 7)
        if (!month.hasOwnProperty(monthCount)) {
          month[monthCount] = 0
        }
        month[monthCount]++
        return month
      }, {})
    const checkCountValue = months.map((x, i) => {
      return Object.keys(groupedUsersPerDate).includes(x)
        ? { [x]: groupedUsersPerDate[x] }
        : { [x]: 0 }
    })
    setPastYearUsers(checkCountValue)
  }

  const dataSet = {
    labels: months,
    datasets: [
      {
        label: `New Avalon Users ${currentYear}`,
        fill: true,
        backgroundColor: style.primaryBackgroundColor,
        borderColor: style.primaryBorderColor,
        borderWidth: 1,
        data: allUsers.map((item, index) => item[months[index]]),
        type: 'bar',
        barPercentage: 0.8
      },
      {
        label: `Past Year Avalon Users ${currentYear - 1}`,
        fill: false,
        backgroundColor: style.secondaryBackgroundColor,
        borderColor: style.secondaryBorderColor,
        borderWidth: 1,
        data: pastYearUsers.map((item, index) => item[months[index]]),
        type: 'bar',
        barPercentage: 0.8
      }
    ]
  }

  return (
    <div>
      <Paper
        className={style.paperGraph}
        elevation={3}
        style={{ backgroundColor: style.primaryPaperBackgroundColor }}>
        <Bar
          data={dataSet}
          options={{
            title: { display: true, text: 'Total Users on Board' },
            maintainAspectRatio: false,
            plugins: {
              datalabels: {
                display: val => val.dataset.data[val.dataIndex] !== 0,
                color: 'white',
                anchor: 'end'
              }
            }
          }}></Bar>
      </Paper>
    </div>
  )
}
