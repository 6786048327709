import React from 'react'
import styles from './delete-org-alert-dialog-style.module.scss'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DeleteIcon from '@material-ui/icons/Delete'
import Typography from '@material-ui/core/Typography'

export default function AlertDialog(props) {
  const [open, setOpen] = React.useState(props.openDialog)
  var orgId = props.dataFromAPI

  const handleClose = () => {
    setOpen(false)
  }

  const handleSubmit = event => {
    event.preventDefault()
    props.handleDeleteObject(orgId)
    setOpen(false)
  }

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogContent classes={{ root: styles.dialogBox }}>
          <Typography variant="h5" align="left" className={styles.dialogTitle}>
            Delete Organization
          </Typography>
          <DialogContentText style={{ color: styles.textColor }}>
            You are about to delete the following organization:{' '}
            {
              <ins style={{ color: styles.helperTextColor, fontWeight: 'bold' }}>
                {props.orgNameAttribute}
              </ins>
            }
            , are you sure about it?
          </DialogContentText>
          <div className={styles.actionButtons}>
            <form onSubmit={handleSubmit}>
              <Button onClick={handleClose} color="secondary" size="small">
                Dismiss
              </Button>
              <Button
                variant="contained"
                color="secondary"
                startIcon={<DeleteIcon />}
                type="submit"
                size="small">
                Delete
              </Button>
            </form>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  )
}
