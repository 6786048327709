import React from 'react'
import styles from './edit-users-dialog-style.module.scss'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Typography from '@material-ui/core/Typography'

export default function EditUsersDialog(props) {
  const [open, setOpen] = React.useState(props.openDialog)
  const [userFirstName, setUserFirstName] = React.useState(props.data.firstName)
  const [userLastName, setUserLastName] = React.useState(props.data.lastName)
  const [_username, setUserName] = React.useState(props.data.username)
  const [email, setUserEmail] = React.useState(props.data.email)

  const userId = props.userID ? true : false

  const handleClose = () => {
    setOpen(false)
  }

  const handleSubmit = event => {
    event.preventDefault()
    if (userId) {
      props.inLineUpdate(props.userID, props.orgId, {
        firstName: userFirstName,
        lastName: userLastName
      })
    } else {
      props.handleInput({
        ...props.data,
        firstName: userFirstName,
        lastName: userLastName,
        username: _username,
        email: email
      })
    }
    setOpen(false)
  }

  return (
    <div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <form onSubmit={handleSubmit}>
          <DialogContent classes={{ root: styles.dialogBox }}>
            <Typography variant="h5" id="form-dialog-title" className={styles.dialogTitle}>
              Edit Details
            </Typography>
            <TextField
              autoFocus
              variant="outlined"
              margin="dense"
              id="firstName"
              label="First Name"
              defaultValue={props.userFirstName}
              type="text"
              fullWidth
              onInput={event => setUserFirstName(event.target.value)}
              helperText="Required*"
              InputProps={{
                classes: { root: styles.textFieldStyle }
              }}
              InputLabelProps={{
                style: { color: styles.textColor }
              }}
              FormHelperTextProps={{
                style: { color: styles.helperTextColor }
              }}
            />
            <TextField
              variant="outlined"
              margin="dense"
              id="lastName"
              label="Last Name"
              defaultValue={props.userLastName}
              type="text"
              fullWidth
              onInput={event => setUserLastName(event.target.value)}
              helperText="Required*"
              InputProps={{
                classes: { root: styles.textFieldStyle }
              }}
              InputLabelProps={{
                style: { color: styles.textColor }
              }}
              FormHelperTextProps={{
                style: { color: styles.helperTextColor }
              }}
            />
            <div className={styles.disabledFields}>
              <TextField
                variant="outlined"
                margin="dense"
                id="username"
                label="User Name"
                defaultValue={props.userUserName}
                type="text"
                fullWidth
                onInput={event => setUserName(event.target.value)}
                disabled={userId}
                helperText="Disabled Field"
                InputProps={{
                  classes: { root: styles.textFieldStyleDisabled }
                }}
                InputLabelProps={{
                  style: { color: styles.helperTextColorDisabledField }
                }}
                FormHelperTextProps={{
                  style: { color: styles.helperTextColorDisabledField }
                }}
              />
              <TextField
                variant="outlined"
                margin="dense"
                id="whitelist"
                label="Email"
                defaultValue={props.userEmail}
                type="email"
                fullWidth
                onInput={event => setUserEmail(event.target.value)}
                disabled={userId}
                helperText="Disabled Field"
                InputProps={{
                  classes: { root: styles.textFieldStyleDisabled }
                }}
                InputLabelProps={{
                  style: { color: styles.helperTextColorDisabledField }
                }}
                FormHelperTextProps={{
                  style: { color: styles.helperTextColorDisabledField }
                }}
              />
            </div>
            <div className={styles.actionButtons}>
              <Button onClick={handleClose} color="primary" size="small">
                Cancel
              </Button>
              <Button color="primary" type="submit" size="small" variant="contained">
                Submit
              </Button>
            </div>
          </DialogContent>
        </form>
      </Dialog>
    </div>
  )
}
