import React from 'react'
import styles from './edit-org-disabled-params-style.module.scss'
import Paper from '@material-ui/core/Paper'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Typography from '@material-ui/core/Typography'
import { Tooltip } from '@material-ui/core'

export default function OrgDisabledParamsPaper({ data }) {
  const currentOrg = data[0]
  const textColor = { color: styles.disabledTextColor }

  return (
    <div>
      <Typography
        color="primary"
        variant="h6"
        style={{ margin: '10px', color: styles.textColor }}
        align="center">
        Read-Only attributes
      </Typography>
      <Tooltip title="Disabled Items">
        <Paper classes={{ root: styles.readOnlyContentsContainer }} elevation={4}>
          <FormControlLabel
            control={
              <Checkbox
                checked={currentOrg.RestrictCustomerEnrichmentToLockedGraphs}
                name="RestrictCustomerEnrichmentToLockedGraphs"
                color="primary"
                disabled
                style={textColor}
              />
            }
            label={
              <Typography variant="subtitle1" style={textColor}>
                Restrict Customer Enrichment to Locked Graphs
              </Typography>
            }
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={currentOrg.LockWorkspacesToOrgOnly}
                name="LockWorkspacesToOrgOnly"
                color="primary"
                disabled
                style={textColor}
              />
            }
            label={
              <Typography variant="subtitle1" style={textColor}>
                Lock Workspaces to Org Only
              </Typography>
            }
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={currentOrg.ForceWorkspaceSharedWithOrg}
                name="ForceWorkspaceSharedWithOrg"
                color="primary"
                disabled
                style={textColor}
              />
            }
            label={
              <Typography variant="subtitle1" style={textColor}>
                Force Workspace Shared With Org
              </Typography>
            }
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={currentOrg.ShareLockedGraphsWithChildOrgs}
                name="ShareLockedGraphsWithChildOrgs"
                color="primary"
                disabled
                style={textColor}
              />
            }
            label={
              <Typography variant="subtitle1" style={textColor}>
                Share Locked Graphs With Child Orgs
              </Typography>
            }
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={currentOrg.ShareLockedGraphsWithParentOrg}
                name="ShareLockedGraphsWithParentOrg"
                color="primary"
                disabled
                style={textColor}
              />
            }
            label={
              <Typography variant="subtitle1" style={textColor}>
                Share Locked Graphs With Parent Org
              </Typography>
            }
          />
        </Paper>
      </Tooltip>
    </div>
  )
}
