import React from 'react'
import styles from './add-user-dialog-style.module.scss'
import { submitNewAvalonUser } from '../../../utils/api-utils'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Typography from '@material-ui/core/Typography'

export default function AddUserDialog({ open, data, submit }) {
  const [openDialog, setOpen] = React.useState(open)

  const [state, setState] = React.useState({
    firstName: '',
    lastName: '',
    username: '',
    email: '',
    emailValid: '',
    memberSince: new Date(),
    Organization: data
  })

  const handleInputChange = event => {
    event.preventDefault()
    submitNewAvalonUser(data, [state])
      .then(alert => {
        alert ? setOpen(true) : setOpen(false)
      })
      .then(() => submit())
  }
  const handleClose = () => {
    setOpen(false)
  }

  const handleInput = event => {
    setState({ ...state, [event.target.name]: event.target.value })
  }

  return (
    <Dialog open={openDialog} onClose={handleClose} aria-labelledby="form-dialog-title">
      <form onSubmit={handleInputChange}>
        <DialogContent classes={{ root: styles.dialogBox }}>
          <Typography variant="h5" align="left" className={styles.dialogTitle}>
            Add New AvalonUser
          </Typography>
          <TextField
            className={styles.textFieldSize}
            autoFocus
            variant="outlined"
            margin="dense"
            name="firstName"
            label="First Name"
            type="text"
            fullWidth
            onInput={handleInput}
            required={true}
            helperText="Required*"
            InputProps={{
              classes: { root: styles.textFieldStyle }
            }}
            InputLabelProps={{
              style: { color: styles.textColor }
            }}
            FormHelperTextProps={{
              style: { color: styles.helperTextColor }
            }}
          />
          <TextField
            variant="outlined"
            margin="dense"
            name="lastName"
            label="Last Name"
            type="text"
            fullWidth
            onInput={handleInput}
            required={true}
            helperText="Required*"
            InputProps={{
              classes: { root: styles.textFieldStyle }
            }}
            InputLabelProps={{
              style: { color: styles.textColor }
            }}
            FormHelperTextProps={{
              style: { color: styles.helperTextColor }
            }}
          />
          <TextField
            variant="outlined"
            margin="dense"
            name="username"
            label="Username"
            type="text"
            fullWidth
            onInput={handleInput}
            required={true}
            helperText="Required*"
            InputProps={{
              classes: { root: styles.textFieldStyle }
            }}
            InputLabelProps={{
              style: { color: styles.textColor }
            }}
            FormHelperTextProps={{
              style: { color: styles.helperTextColor }
            }}
          />
          <TextField
            variant="outlined"
            margin="dense"
            name="email"
            label="Email Address"
            type="email"
            fullWidth
            onInput={handleInput}
            required={true}
            helperText="Required*"
            InputProps={{
              classes: { root: styles.textFieldStyle }
            }}
            InputLabelProps={{
              style: { color: styles.textColor }
            }}
            FormHelperTextProps={{
              style: { color: styles.helperTextColor }
            }}
          />
          <div className={styles.actionButtons}>
            <Button color="primary" onClick={handleClose} size="small">
              Cancel
            </Button>
            <Button color="primary" type="submit" variant="contained" size="small">
              Submit
            </Button>
          </div>
        </DialogContent>
      </form>
    </Dialog>
  )
}
