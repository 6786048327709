import React from 'react'
import styles from './restrictions-checkbox-page-style.module.scss'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'

export default function RestrictionsCheckbox({ values, onChange, parentOrgValue }) {
  const propsAttribute = values

  return (
    <div>
      <FormGroup column={'true'}>
        <FormControlLabel
          control={
            <Checkbox
              checked={propsAttribute.Enable2FA}
              onChange={onChange}
              name="Enable2FA"
              color="primary"
            />
          }
          label="Enable2FA"
          className={styles.checkboxTextStyle}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={propsAttribute.restrictCustomerEnrichment}
              onChange={onChange}
              name="restrictCustomerEnrichment"
              color="primary"
            />
          }
          label="Restrict Customer Enrichment To Locked Graphs"
          className={styles.checkboxTextStyle}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={propsAttribute.lockWorkSpaces}
              onChange={onChange}
              name="lockWorkSpaces"
              color="primary"
            />
          }
          label="Lock Work spaces To Organization Only"
          className={styles.checkboxTextStyle}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={propsAttribute.forceWorkSpaceShared}
              onChange={onChange}
              name="forceWorkSpaceShared"
              color="primary"
            />
          }
          label="Force Work space Shared With Organization"
          className={styles.checkboxTextStyle}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={propsAttribute.shareLockedGraphsChild}
              onChange={onChange}
              name="shareLockedGraphsChild"
              color="primary"
            />
          }
          label="Share Locked Graphs With Child Organizations"
          className={styles.checkboxTextStyle}
        />
        {parentOrgValue ? (
          <FormControlLabel
            control={
              <Checkbox
                checked={propsAttribute.shareLockedGraphsParent}
                onChange={onChange}
                name="shareLockedGraphsParent"
                color="primary"
              />
            }
            label="Share Locked Graphs With Parent Organization"
            className={styles.checkboxTextStyle}
          />
        ) : null}
      </FormGroup>
    </div>
  )
}
