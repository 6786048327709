import React from 'react'
import styles from './edit-org-less-users-style.module.scss'
import { isEmpty } from 'lodash'
import { getAllOrganizations } from '../../../utils/api-utils'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Typography from '@material-ui/core/Typography'
import MenuItem from '@material-ui/core/MenuItem'
import ClearIcon from '@material-ui/icons/Clear'
import IconButton from '@material-ui/core/IconButton'

export default function EditOrgLessUsers(props) {
  const [open, setOpen] = React.useState(props.openDialog)
  const [userFirstName, setUserFirstName] = React.useState(props.data.firstName)
  const [userLastName, setUserLastName] = React.useState(props.data.lastName)
  const [_username, setUserName] = React.useState(props.data.username)
  const [email, setUserEmail] = React.useState(props.data.email)
  const [orgID, setOrgID] = React.useState('')
  const [allOrganizationsList, setAllOrganizationsList] = React.useState([])
  const isUserIdNotEmpty = isEmpty(props.userID)

  React.useEffect(() => {
    getOrganizationsList()
    // eslint-disable-next-line
  }, [])

  const getOrganizationsList = () => {
    getAllOrganizations().then(orgs => setAllOrganizationsList(orgs))
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleSubmit = event => {
    event.preventDefault()
    if (isUserIdNotEmpty) {
      props.inLineUpdate(props.userID, {
        Organization: orgID ? orgID : null,
        firstName: userFirstName,
        lastName: userLastName
      })
    } else {
      props.handleInput({
        ...props.data,
        firstName: userFirstName,
        lastName: userLastName,
        username: _username,
        email: email
      })
    }
    setOpen(false)
  }

  const handleAdornmentView = () => {
    return (
      <IconButton style={{ marginRight: 25 }} onClick={e => setOrgID('')}>
        <ClearIcon fontSize="small" />
      </IconButton>
    )
  }

  return (
    <div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <form onSubmit={handleSubmit}>
          <DialogContent classes={{ root: styles.dialogBox }}>
            <Typography variant="h5" id="form-dialog-title" className={styles.dialogTitle}>
              Edit Org Less User
              <IconButton style={{ float: 'right' }} onClick={handleClose}>
                <ClearIcon />
              </IconButton>
            </Typography>
            <TextField
              margin="dense"
              label="Assign Organization"
              select
              variant="outlined"
              fullWidth
              id="organization"
              value={orgID}
              onChange={event => setOrgID(event.target.value)}
              InputProps={{
                endAdornment: orgID ? handleAdornmentView() : null,
                classes: { root: styles.textFieldStyle }
              }}
              InputLabelProps={{
                style: { color: styles.textColor }
              }}
              SelectProps={{
                MenuProps: {
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left'
                  },
                  getContentAnchorEl: null,
                  style: { maxHeight: '30%' }
                }
              }}
              helperText="Here you can assign org-less-user to any valid Organization"
              FormHelperTextProps={{
                style: { color: styles.helperTextColorDisabledField }
              }}>
              {allOrganizationsList.map(option => (
                <MenuItem
                  key={option.Name}
                  value={option.id}
                  classes={{ root: styles.menuItem, selected: styles.selectedItem }}>
                  {option.Name}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              autoFocus
              margin="dense"
              id="firstName"
              label="First Name"
              variant="outlined"
              defaultValue={props.userFirstName}
              type="text"
              fullWidth
              onInput={event => setUserFirstName(event.target.value)}
              InputProps={{
                classes: { root: styles.textFieldStyle }
              }}
              InputLabelProps={{
                style: { color: styles.textColor }
              }}
            />
            <TextField
              margin="dense"
              id="lastName"
              label="Last Name"
              variant="outlined"
              defaultValue={props.userLastName}
              type="text"
              fullWidth
              onInput={event => setUserLastName(event.target.value)}
              InputProps={{
                classes: { root: styles.textFieldStyle }
              }}
              InputLabelProps={{
                style: { color: styles.textColor }
              }}
            />
            <div className={styles.disabledFields}>
              <TextField
                margin="dense"
                id="username"
                label="User Name"
                variant="outlined"
                defaultValue={props.userUserName}
                type="text"
                fullWidth
                onInput={event => setUserName(event.target.value)}
                disabled={isUserIdNotEmpty}
                helperText="Disabled Field"
                InputProps={{
                  classes: { root: styles.textFieldStyleDisabled }
                }}
                InputLabelProps={{
                  style: { color: styles.helperTextColorDisabledField }
                }}
                FormHelperTextProps={{
                  style: { color: styles.helperTextColorDisabledField }
                }}
              />

              <TextField
                margin="dense"
                id="whitelist"
                label="Email"
                variant="outlined"
                defaultValue={props.userEmail}
                type="email"
                fullWidth
                onInput={event => setUserEmail(event.target.value)}
                disabled={isUserIdNotEmpty}
                helperText="Disabled Field"
                InputProps={{
                  classes: { root: styles.textFieldStyleDisabled }
                }}
                InputLabelProps={{
                  style: { color: styles.helperTextColorDisabledField }
                }}
                FormHelperTextProps={{
                  style: { color: styles.helperTextColorDisabledField }
                }}
              />
            </div>
            <div className={styles.actionButtons}>
              <Button onClick={handleClose} color="primary" size="small">
                Cancel
              </Button>
              <Button color="primary" type="submit" variant="contained" size="small">
                Submit
              </Button>
            </div>
          </DialogContent>
        </form>
      </Dialog>
    </div>
  )
}
