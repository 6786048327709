import React from 'react'
import styles from './edit-org-main-dialog-style.module.scss'
import OrgDisabledParamsPaper from './edit-org-disabled-params'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Paper from '@material-ui/core/Paper'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Typography from '@material-ui/core/Typography'
import ClearIcon from '@material-ui/icons/Clear'
import { Grid, IconButton } from '@material-ui/core'
import MenuItem from '@material-ui/core/MenuItem'
import { isEmpty } from 'lodash'
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'

const newOrgNameInstruction = 'unique and case sensitive'
const errorOrgNameExists = 'Error! this name already exists.'

export default function EditSectionDialog(props) {
  const currentOrg = props.data[0]
  const [open, setOpen] = React.useState(props.openDialog)
  const [orgName, setOrgName] = React.useState('')
  const [parentOrg, setParentOrg] = React.useState(currentOrg.ParentOrganization.id || '')
  const [digitsTypeRestrictions, setDigitsTypeRestrictions] = React.useState({
    passwordCount: currentOrg.ReusablePasswordCount,
    session: currentOrg.SessionExpiry,
    maxSeats: currentOrg.MaxSeatCounts
  })

  const [unlimitedSeatsCheckBox, setUnlimitedSeatsCheckBox] = React.useState(
    digitsTypeRestrictions.maxSeats === -1
  )

  const [unlimitedLicense, setUnlimitedLicense] = React.useState({
    unlimitedLicenseCheckBox: isEmpty(currentOrg.EndDate),
    licenseEndDate: isEmpty(currentOrg.EndDate) ? null : currentOrg.EndDate.slice(0, -1)
  })

  const [errorAlert, setErrorAlert] = React.useState({
    message: newOrgNameInstruction,
    showErrorAlert: false,
    errorField: false
  })

  const handleOnInputUpdate = event => {
    setDigitsTypeRestrictions({
      ...digitsTypeRestrictions,
      [event.target.name]: event.target.value
    })
    handleValidationField(event)
  }

  const handleClose = () => {
    setOpen(false)
  }

  function handleSubmit(event) {
    event.preventDefault()
    if (!doesNewOrgAlreadyExists()) {
      setErrorAlert({
        message: errorOrgNameExists,
        showErrorAlert: true
      })
      return
    } else {
      props.handleUpdateObject(props.dataFromAPI, {
        Name: currentOrg.Name !== orgName && orgName !== '' ? orgName : currentOrg.Name,
        ParentOrg: parentOrg ? parentOrg : null,
        SessionExpiry: digitsTypeRestrictions.session,
        ReusablePasswordCount: digitsTypeRestrictions.passwordCount,
        MaxSeatCounts: unlimitedSeatsCheckBox ? -1 : digitsTypeRestrictions.maxSeats,
        EndDate: unlimitedLicense.unlimitedLicenseCheckBox ? null : unlimitedLicense.licenseEndDate
      })
      setOpen(false)
    }
  }

  const doesNewOrgAlreadyExists = () => {
    return props.allOrgs.findIndex(item => item.name.toLowerCase() === orgName.toLowerCase()) === -1
  }

  const handleCheckBoxChange = event => {
    setUnlimitedSeatsCheckBox(event.target.checked)
  }

  const handleValidationField = event => {
    setErrorAlert({ ...errorAlert, errorField: !event.target.checkValidity() })
  }

  const handleAdornmentView = event => {
    return parentOrg ? (
      <IconButton
        style={{ marginRight: 5, color: styles.textColor }}
        onClick={() => {
          setParentOrg('')
        }}>
        <ClearIcon fontSize="small" />
      </IconButton>
    ) : null
  }

  return (
    <div>
      <Dialog open={open} onClose={handleClose} maxWidth="md">
        <form onSubmit={handleSubmit}>
          <DialogContent className={styles.dialogContainer}>
            <Typography
              variant="h5"
              style={{ marginBottom: '10px', color: styles.textColor }}
              align="left">
              Edit organization
              <IconButton
                style={{ float: 'right', marginRight: '5px', color: styles.textColor }}
                onClick={handleClose}>
                <ClearIcon />
              </IconButton>
            </Typography>
            <Grid container spacing={1}>
              <Grid item md={8}>
                <Typography
                  color="primary"
                  variant="h6"
                  style={{ marginLeft: '34px', color: styles.textColor }}
                  align="center">
                  Editable attributes
                </Typography>
                <Paper elevation={3} classes={{ root: styles.paperContainer }}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={7}>
                      <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        fullWidth
                        label="New Organization Name"
                        type="text"
                        onInput={event => {
                          setOrgName(event.target.value)
                          setErrorAlert({
                            message: newOrgNameInstruction,
                            showErrorAlert: false
                          })
                        }}
                        variant="outlined"
                        autoComplete="off"
                        error={errorAlert.showErrorAlert}
                        helperText={errorAlert.message}
                        InputLabelProps={{
                          style: { color: styles.textColor }
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={5}>
                      <TextField
                        name="parentOrg"
                        fullWidth
                        select
                        variant="outlined"
                        label="Parent Organization"
                        value={parentOrg}
                        onChange={e => {
                          setParentOrg(e.target.value)
                        }}
                        margin="dense"
                        SelectProps={{
                          MenuProps: {
                            anchorOrigin: {
                              vertical: 'bottom',
                              horizontal: 'left'
                            },
                            getContentAnchorEl: null,
                            style: { maxHeight: '30%' }
                          }
                        }}
                        InputProps={{
                          endAdornment: handleAdornmentView()
                        }}
                        InputLabelProps={{
                          style: { color: styles.textColor }
                        }}>
                        {props.allOrgs.map(option => (
                          <MenuItem
                            key={option.name}
                            value={option.id}
                            classes={{ root: styles.menuItem, selected: styles.selectedItem }}>
                            {option.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                  </Grid>
                  <TextField
                    margin="dense"
                    name="passwordCount"
                    label="Reusable Password Count"
                    defaultValue={digitsTypeRestrictions.passwordCount}
                    type="number"
                    fullWidth
                    onInput={event => handleOnInputUpdate(event)}
                    variant="outlined"
                    InputProps={{
                      inputProps: {
                        min: 1,
                        max: 2147483647
                      }
                    }}
                    InputLabelProps={{
                      style: { color: styles.textColor }
                    }}
                    helperText="The default value is 4"
                  />
                  <TextField
                    margin="dense"
                    name="session"
                    label="Session Expiry"
                    defaultValue={digitsTypeRestrictions.session}
                    type="number"
                    fullWidth
                    onInput={event => handleOnInputUpdate(event)}
                    variant="outlined"
                    helperText="Please enter a value in seconds (60 = 1 min, 600 = 10 min)."
                    InputLabelProps={{
                      style: { color: styles.textColor }
                    }}
                  />
                  <TextField
                    margin="dense"
                    name="maxSeats"
                    label="Max Seat Counts"
                    value={unlimitedSeatsCheckBox ? '\u221e' : digitsTypeRestrictions.maxSeats}
                    type={unlimitedSeatsCheckBox ? 'text' : 'number'}
                    disabled={unlimitedSeatsCheckBox}
                    onInput={event => handleOnInputUpdate(event)}
                    variant="outlined"
                    InputProps={{
                      inputProps: {
                        min: 1
                      }
                    }}
                    InputLabelProps={{
                      style: { color: styles.textColor }
                    }}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        style={{ marginLeft: '15px' }}
                        checked={unlimitedSeatsCheckBox}
                        onChange={event => handleCheckBoxChange(event)}
                        name="unlimitedSeats"
                        color="primary"
                      />
                    }
                    label="Unlimited Seats"
                  />
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={6}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDateTimePicker
                          autoComplete="off"
                          name="date"
                          inputVariant="outlined"
                          disablePast
                          format="MM/dd/yyyy :- hh:mm a"
                          value={unlimitedLicense.licenseEndDate}
                          onChange={event =>
                            setUnlimitedLicense({
                              ...unlimitedLicense,
                              licenseEndDate: event
                            })
                          }
                          disabled={unlimitedLicense.unlimitedLicenseCheckBox}
                          margin="dense"
                          animateYearScrolling={true}
                          helperText="Pick a date and time for license expiration."
                          clearable
                          error={false}
                          autoOk={true}
                          KeyboardButtonProps={{
                            style: { color: styles.textColor }
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            style={{ marginLeft: '15px' }}
                            checked={unlimitedLicense.unlimitedLicenseCheckBox}
                            onChange={event =>
                              setUnlimitedLicense({
                                ...unlimitedLicense,
                                unlimitedLicenseCheckBox: event.target.checked,
                                licenseEndDate: null
                              })
                            }
                            name="unlimitedLicense"
                            color="primary"
                          />
                        }
                        label="Unlimited License"
                      />
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>

              <Grid item md={4}>
                <OrgDisabledParamsPaper {...props} />
              </Grid>
            </Grid>
            <div className={styles.actionButtons}>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button color="primary" type="submit" variant="contained">
                Submit
              </Button>
            </div>
          </DialogContent>
        </form>
      </Dialog>
    </div>
  )
}
