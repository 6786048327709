import React from 'react'
import ReactDOM from 'react-dom'
import App from './routes/app'
import { BrowserRouter } from 'react-router-dom'
import axios from 'axios'
import { getUserCookie } from './views/utils/auth-service-cookie'

const token = getUserCookie('authToken')

const API_ENV = process.env['REACT_APP_API_ENV']
var API_URL = API_ENV ? process.env[`REACT_APP_${API_ENV}_API`] : 'http://localhost:8000'

axios.defaults.baseURL = API_URL
axios.defaults.headers.post['Content-Type'] = 'application/json'

if (token) {
  axios.defaults.headers.common['Authorization'] = `Token ${token}`
}

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById('root')
)
