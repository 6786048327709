import React from 'react'
import styles from './activate-deactivate-switch-style.module.scss'
import { submitUpdateAvalonUser } from '../utils/api-utils'
import Switch from '@material-ui/core/Switch'
import Typography from '@material-ui/core/Typography'
import { Tooltip } from '@material-ui/core'

export default function ActiveUserSwitch({ orgID, userID, checked }) {
  const [isActiveUser, setState] = React.useState(checked)

  const handleChange = event => {
    setState(event.target.checked)
    submitUpdateAvalonUser(userID, orgID, { 'isActive': !isActiveUser })
  }

  return (
    <Typography component="div">
      <Tooltip title={isActiveUser ? 'Activate' : 'Deactivate'}>
        <Switch
          checked={isActiveUser}
          onChange={handleChange}
          name="isActiveUser"
          classes={
            isActiveUser ? { checked: styles.antSwitch } : { switchBase: styles.antSwitchBase }
          }
        />
      </Tooltip>
    </Typography>
  )
}
