import React from 'react'
import styles from './admin-user-status-style.module.scss'
import { submitAdminStatusUpdate } from '../utils/api-utils'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'

export default function AdminCheckBox(props) {
  const [adminChecked, setAdminChecked] = React.useState(props.checked)

  const handleAdminStatus = e => {
    setAdminChecked(!adminChecked)
    submitAdminStatusUpdate(props.userID, props.orgID, {
      firstName: props.firstName,
      lastName: props.lastName,
      IsOrgAdmin: !adminChecked
    })
    props.onAdminStatusChangeGetRows()
  }
  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={adminChecked}
          onChange={handleAdminStatus}
          name="AdminStatus"
          color="primary"
        />
      }
      label="Admin"
      className={adminChecked ? styles.statusStyleActive : styles.statusStyleDisabled}
    />
  )
}
