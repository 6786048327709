import React from 'react'
import { BrowserRouter as Router, withRouter, Switch, Route, Redirect } from 'react-router-dom'
import Dashboard from './dashboard-page'
import NotFound from '../views/not-found'
import PrivateRoute from '../views/utils/private-route'
import OrganizationsMainTable from '../views/components/organizations-main-table'
import OrgAllUsersPage from './org-page'
import OrgLessView from './org-less-users-table'
import Login from './login-page'
import Sidebar from '../views/components/sidebar/sidebar'
import Experimental from './experimental'
import Organizations from './organizations'
import Users from './users'

function App() {
  return (
    <div>
      <Router>
        <Switch>
          <Route exact path="/" component={Login} />
          <React.Fragment>
            <PrivateRoute exact path="/experimental" component={Experimental} />
            <PrivateRoute exact path="/experimental/organizations" component={Organizations} />
            <PrivateRoute exact path="/experimental/users" component={Users} />
            <Sidebar />
            <PrivateRoute exact path="/dashboard" component={Dashboard} />
            <PrivateRoute exact path="/organizations/:id" component={OrgAllUsersPage} />
            <PrivateRoute exact path="/org-less-users-view" component={OrgLessView} />
            <PrivateRoute exact path="/organization-less" component={OrgLessView} />
            <PrivateRoute exact path="/organizations" component={OrganizationsMainTable} />
          </React.Fragment>
          <Route exact path="*" component={NotFound} />
          <Redirect to="/404" from="*" component={NotFound} />
        </Switch>
      </Router>
    </div>
  )
}

export default withRouter(App)
