import React from 'react'
import ReactDOM from 'react-dom'
import './scss/org-page-style.scss'
import {
  submitNewAvalonUser,
  submitDeleteAvalonUser,
  submitUpdateAvalonUser,
  resetPasswordForAvalonUser
} from '../views/utils/api-utils'
import AdminCheckBox from '../views/components/admin-user-status'
import ActiveUserSwitch from '../views/components/activate-deactivate-switch-user'
import { processImportFile } from '../views/utils/import-user-list'
import AlertDialogMessage from '../views/components/dialogs/user-dialogs/delete-user-alert'
import axios from 'axios'
import MaterialTable, { MTableToolbar } from 'material-table'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import TablePagination from '@material-ui/core/TablePagination'
import EditTwoToneIcon from '@material-ui/icons/EditTwoTone'
import AddBoxTwoToneIcon from '@material-ui/icons/AddBoxTwoTone'
import DeleteForeverTwoToneIcon from '@material-ui/icons/DeleteForeverTwoTone'
import SearchTwoToneIcon from '@material-ui/icons/SearchTwoTone'
import ClearIcon from '@material-ui/icons/Clear'
import LastPageTwoToneIcon from '@material-ui/icons/LastPageTwoTone'
import PageviewTwoToneIcon from '@material-ui/icons/PageviewTwoTone'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import CheckIcon from '@material-ui/icons/Check'
import FilterListIcon from '@material-ui/icons/FilterList'
import FirstPageIcon from '@material-ui/icons/FirstPage'
import GetAppIcon from '@material-ui/icons/GetApp'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import Button from '@material-ui/core/Button'
import EditUsersDialog from '../views/components/dialogs/user-dialogs/edit-users-dialog'
import AddUserDialog from '../views/components/dialogs/user-dialogs/add-user-dialog'
import ResetPasswordForAvalonUser from '../views/reset-password-snackbar-alert'
import Hidden from '@material-ui/core/Hidden'
import UnarchiveIcon from '@material-ui/icons/Unarchive'
import Typography from '@material-ui/core/Typography'
import { ReactComponent as PasswordIcon } from '../assets/icons/password.svg'

const fontColor = '#A7A7BA'

const theme = createMuiTheme({
  typography: {
    caption: {
      color: fontColor,
      fontSize: 14
    },
    h6: {
      color: fontColor
    }
  }
})

const icons = {
  Delete: React.forwardRef((props, ref) => (
    <RemoveCircleOutlineIcon style={{ color: '#FF6775' }} ref={ref} />
  )),
  Check: React.forwardRef((props, ref) => <CheckIcon style={{ color: fontColor }} ref={ref} />),
  Clear: React.forwardRef((props, ref) => <ClearIcon style={{ color: fontColor }} ref={ref} />),
  Search: React.forwardRef((props, ref) => <SearchTwoToneIcon ref={ref} />),
  NextPage: React.forwardRef((props, ref) => (
    <ArrowForwardIosIcon style={{ color: fontColor }} ref={ref} />
  )),
  LastPage: React.forwardRef((props, ref) => (
    <LastPageTwoToneIcon style={{ color: fontColor }} ref={ref} />
  )),
  SortArrow: React.forwardRef((props, ref) => <FilterListIcon ref={ref} />),
  PreviousPage: React.forwardRef((props, ref) => (
    <ArrowBackIosIcon style={{ color: fontColor }} ref={ref} />
  )),
  ViewColumn: React.forwardRef((props, ref) => (
    <PageviewTwoToneIcon style={{ color: fontColor }} ref={ref} />
  )),
  FirstPage: React.forwardRef((props, ref) => (
    <FirstPageIcon style={{ color: fontColor }} ref={ref} />
  )),
  ResetSearch: React.forwardRef((props, ref) => (
    <ClearIcon style={{ color: fontColor }} ref={ref} />
  )),
  Export: React.forwardRef((props, ref) => <UnarchiveIcon style={{ color: fontColor }} ref={ref} />)
}

const style = {
  position: 'absolute',
  fontFamily: 'verdana',
  fontSize: '14px',
  top: '120px',
  left: '250px',
  margin: '20px',
  backgroundColor: '#27293D',
  borderRadius: '0px',
  borderColor: '#353A53',
  borderStyle: 'solid',
  color: fontColor,
  width: 'calc(100vw - 300px)'
}

const columns = [
  { title: 'UserID', field: 'id', align: 'center', sorting: false },
  { title: 'First Name', field: 'firstName' },
  { title: 'Last Name', field: 'lastName' },
  { title: 'User Name', field: 'username' },
  { title: 'Email', field: 'email', sorting: false },
  { title: 'Email Validation', field: 'emailValid', sorting: false },
  { title: 'Admin', field: 'IsOrgAdmin', sorting: false },
  { title: 'Active Status', field: 'activeStatus', sorting: false },
  { title: 'Last Login', field: 'lastLogin' },
  { title: 'Invited By', field: 'invitedBy' },
  { title: 'Date Created', field: 'memberSince', type: 'date', sorting: false }
]

function OrgAllUsersPage(props) {
  const organizationID = props.match.params.id
  const organizationName = props.location.hash.slice(1)
  const organizationMaxSeatsCount = props.location.state
  const inputFile = React.useRef('')
  const [loading, setIsLoading] = React.useState(true)
  const [rows, setRows] = React.useState([])
  const [isButtonVisible, setIsButtonVisible] = React.useState(false)

  React.useEffect(() => {
    getUsersFromOrg()
    // eslint-disable-next-line
  }, [])

  const getUsersFromOrg = () => {
    setIsLoading(true)
    axios
      .get(`/api/admin/organizations/${organizationID}/user/`)
      .then(response => {
        setRows(getRows(response.data))
        setIsLoading(false)
      })
      .catch(error => {
        return error
      })
  }

  const getRows = users => {
    const rows = users.map(user => ({
      id: user.id,
      firstName: user.First_name || user.firstName || user.first_name,
      lastName: user.Last_name || user.lastName || user.last_name,
      username: user.username || user.Username,
      email: user.email || user.Email,
      emailValid: user.emailValid || user.Email_Valid,
      IsOrgAdmin: user.id ? (
        <AdminCheckBox
          onAdminStatusChangeGetRows={() => {
            setTimeout(function () {
              getUsersFromOrg()
              setIsLoading(false)
            }, 1000)
          }}
          checked={user.IsOrgAdmin}
          userID={user.id}
          orgID={organizationID}
          firstName={user.firstName}
          lastName={user.lastName}
        />
      ) : (
        ''
      ),
      activeStatus: user.id ? (
        <ActiveUserSwitch checked={user.isActive} userID={user.id} orgID={organizationID} />
      ) : (
        ''
      ),
      lastLogin: user.LastLogin,
      invitedBy: user.InvitedBy === null ? 'N/A' : user.InvitedBy,
      memberSince: user.DateJoined,
      Organization: organizationID
    }))
    return rows
  }

  const processFile = event => {
    processImportFile(event.target.files[0]).then(users => {
      const newRows = getRows(users)
      setRows(rows.concat(newRows))
      setIsButtonVisible(true)
    })
  }

  const updateRow = newRow => {
    const newRows = rows.map(r => {
      if (r.tableData.id === newRow.tableData.id) {
        return newRow
      }
      return r
    })
    setRows(newRows)
    setIsButtonVisible(true)
  }

  const handleSubmit = event => {
    event.preventDefault()
    const newUsersFilter = rows.filter(r => {
      return r.id === undefined
    })
    submitNewAvalonUser(organizationID, newUsersFilter).then(() => getUsersFromOrg())
    setIsButtonVisible(false)
  }

  return (
    <div>
      <ThemeProvider theme={theme}>
        <MaterialTable
          icons={icons}
          title={
            <div style={{ color: '#F10075' }}>
              <Typography>Company Name: {organizationName}</Typography>
              <Typography>Max Seats: {organizationMaxSeatsCount}</Typography>
            </div>
          }
          style={style}
          columns={columns}
          data={rows}
          isLoading={loading}
          localization={{
            body: {
              deleteTooltip: 'Clear Row',
              editRow: {
                saveTooltip: 'Confirm'
              }
            }
          }}
          options={{
            draggable: false,
            exportButton: true,
            exportAllData: true,
            pageSize: 10,
            exportFileName: `${organizationName}_all_users`,
            headerStyle: {
              backgroundColor: '#ff3366',
              color: '#ffffff',
              borderBottomColor: '#000000'
            },
            rowStyle: rowData => {
              if (!rowData.id) return { color: '#ffffff', backgroundColor: '#404365' }
            },

            searchFieldStyle: {
              color: fontColor
            },
            actionsCellStyle: {
              color: fontColor
            }
          }}
          components={{
            Toolbar: props => {
              return (
                <div>
                  <MTableToolbar {...props} />
                  <Hidden smDown={true}>
                    <div
                      style={{
                        position: 'absolute',
                        float: 'left',
                        overflow: 'hidden',
                        top: '10px',
                        right: '360px',
                        marginRight: '20px'
                      }}>
                      <Tooltip title="Import Users File">
                        <span>
                          <IconButton
                            type="file"
                            className="add-user"
                            onClick={() => inputFile.current.click()}>
                            <input
                              type="file"
                              ref={inputFile}
                              className={'inputFileDisplay'}
                              onChange={processFile}
                            />
                            <GetAppIcon style={{ color: fontColor }} />
                          </IconButton>
                        </span>
                      </Tooltip>
                      <Button
                        onClick={handleSubmit}
                        className="disabledButton"
                        variant="contained"
                        style={{
                          visibility: isButtonVisible ? 'visible' : 'hidden',
                          backgroundColor: '#67E89',
                          float: 'left',
                          bottom: '5px',
                          margin: '13px 10px'
                        }}
                        size="small"
                        data={rows}>
                        Save
                      </Button>
                    </div>
                  </Hidden>
                </div>
              )
            },
            Pagination: props => <TablePagination {...props} className="paginationOrgTable" />
          }}
          actions={[
            {
              icon: () => <EditTwoToneIcon />,
              position: 'row',
              tooltip: 'Edit',
              onClick: (event, rowData) =>
                ReactDOM.render(
                  <EditUsersDialog
                    openDialog={true}
                    data={rowData}
                    userFirstName={rowData.firstName}
                    userLastName={rowData.lastName}
                    userUserName={rowData.username}
                    userEmail={rowData.email}
                    userID={rowData.id}
                    handleInput={updateRow}
                    orgId={organizationID}
                    inLineUpdate={(userID, orgID, dataResults) => {
                      submitUpdateAvalonUser(userID, orgID, dataResults).then(() =>
                        getUsersFromOrg()
                      )
                    }}
                  />,
                  document.createElement('dialog-placeholder')
                )
            },
            {
              icon: () => <DeleteForeverTwoToneIcon />,
              position: 'row',
              tooltip: 'Delete From DB',
              onClick: (event, rowData) => {
                ReactDOM.render(
                  <AlertDialogMessage
                    openDialog={true}
                    orgID={organizationID}
                    userID={rowData.id}
                    userNameData={rowData.username}
                    handleDeleteObject={(orgId, userId) =>
                      submitDeleteAvalonUser(orgId, userId).then(() => getUsersFromOrg())
                    }
                  />,
                  document.createElement('dialog-placeholder')
                )
              }
            },
            {
              icon: () => <PasswordIcon style={{ fill: fontColor }} />,
              position: 'row',
              tooltip: 'Reset Password',
              onClick: (event, rowData) => {
                ReactDOM.render(
                  <ResetPasswordForAvalonUser
                    openDialog={true}
                    userID={rowData.id}
                    userNameData={rowData.username}
                    resetUserPassword={(userId, data) => resetPasswordForAvalonUser(userId, data)}
                  />,
                  document.createElement('dialog-placeholder')
                )
              }
            },
            {
              icon: () => <AddBoxTwoToneIcon style={{ color: fontColor }} />,
              position: 'toolbar',
              tooltip: 'Add User',
              onClick: (event, rowData) => {
                ReactDOM.render(
                  <AddUserDialog
                    open={true}
                    handleNewUserInLine={getRows}
                    data={organizationID}
                    submit={() => {
                      getUsersFromOrg()
                    }}
                  />,
                  document.createElement('dialog-placeholder')
                )
              }
            }
          ]}
          editable={{
            isDeleteHidden: rowData => rowData.id !== undefined,
            onRowDelete: oldData =>
              new Promise((resolve, reject) => {
                setTimeout(() => {
                  const dataDelete = [...rows]
                  const index = oldData.tableData.id
                  dataDelete.splice(index, 1)
                  setRows([...dataDelete])
                  resolve()
                }, 1000)
              })
          }}
        />
      </ThemeProvider>
    </div>
  )
}

export default OrgAllUsersPage
