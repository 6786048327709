import React from 'react'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

export default function MessageSnackBar(props) {
  const [failOpened, setFailOpened] = React.useState(props.operations === 'error')
  const [successOpened, setSuccessOpened] = React.useState(props.operations === 'success')

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    if (failOpened) {
      setFailOpened(false)
    } else if (successOpened) {
      setSuccessOpened(false)
    }
  }

  return (
    <div>
      <Snackbar
        open={successOpened}
        autoHideDuration={20000}
        onClose={handleClose}
        onExited={props.onExited}>
        <Alert onClose={handleClose} severity="success" style={{ fontSize: '18px' }}>
          {props.message}
        </Alert>
      </Snackbar>
      <Snackbar
        open={failOpened}
        autoHideDuration={5000}
        onClose={handleClose}
        onExited={props.onExited}>
        <Alert onClose={handleClose} severity="error" style={{ fontSize: '18px' }}>
          {props.message}
        </Alert>
      </Snackbar>
    </div>
  )
}
